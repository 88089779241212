<template>
<div>
<el-card class="box-card">
  <div slot="header" class="clearfix">
    <!-- <span class="goback">返回</span> -->
    <el-button @click="$router.back();" type="text">返回</el-button>
    <el-button style="float: right;margin-top:5px;" size="small" plain type="primary">确定</el-button>
  </div>
  <div class="text">
    <el-form 
    ref="form" 
    :model="detailData"
    label-width="120px" 
    style="margin:10px;width:auto;" >
        <el-row :gutter="24">
            <el-col :span="12" style="line-height:36px;">
                <el-form 
                    label-width="80px"
                    style="margin:10px;width:auto;">
                    <el-form-item 
                        prop='order_sn'>
                        <span 
                            slot="label" 
                            class="comFontSize">
                            头像
                        </span>
                        <span>
                        	<img @click="handleForUpload" src="@/assets/images/avatar.png" style="width:50px;" alt="">
                        </span>
                    </el-form-item>

                    <el-form-item 
                        prop='order_sn'>
                        <span 
                            slot="label" 
                            class="comFontSize">
                            账号
                        </span>
                        <span>
                            <el-input v-model="detailData.name"></el-input>
                        </span>
                    </el-form-item>
                    <el-form-item  
                        prop='name'>
                        <span 
                            slot="label" 
                            class="comFontSize">
                            企业ID
                        </span>
                        <span>
                            <el-input v-model="detailData.name"></el-input>
                        </span>
                    </el-form-item>
                    <el-form-item  
                        prop='date'>
                        <span 
                            slot="label" 
                            class="comFontSize">
                            电话号码
                        </span>
                        <span>
                           <el-input v-model="detailData.name"></el-input>
                        </span>
                    </el-form-item>
                    <el-form-item 
                        label="" 
                        prop='order_count'>
                        <span 
                            slot="label" 
                            class="comFontSize">
                            邮箱
                        </span>
                        <span>
                          <el-input v-model="detailData.name"></el-input>
                        </span>
                    </el-form-item>
                  
                </el-form>
            </el-col>
            <el-col 
                :span="12" 
                style="line-height:36px;">
                <el-form 
                    label-width="80px"
                    style="margin:10px;width:auto;">
                    <el-form-item
                        prop='mat_name' 
                        style="textAlign:left;">
                        <span 
                            slot="label" 
                            class="comFontSize">
                            居住地址
                        </span>
                        <span>
                           <el-input v-model="detailData.name"></el-input>
                        </span>
                    </el-form-item>
                    <el-form-item 
                        prop='weight'>
                        <span 
                            slot="label" 
                            class="comFontSize">
                            公司
                        </span>
                        <span>
                         <el-input v-model="detailData.name"></el-input>
                        </span>
                    </el-form-item>
                    <el-form-item 
                        prop='acre'>
                        <span 
                            slot="label" 
                            class="comFontSize">
                            职位
                        </span>
                        <span style="">
                          <el-input v-model="detailData.name"></el-input>
                        </span>
                    </el-form-item>
                    <el-form-item
                        prop='hand_at'>
                        <span 
                            slot="label" 
                            class="comFontSize">
                            部门
                        </span>
                        <span>
                            <el-input v-model="detailData.name"></el-input>
                        </span>
                    </el-form-item>
                    <el-form-item
                        prop='count_price'>
                        <span 
                            slot="label" 
                            class="comFontSize">
                            注册时间
                        </span>
                        <span class="price">
                            <el-input v-model="detailData.name"></el-input>
                        </span>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <br/>
    </el-form>
   </div>
</el-card>
<el-dialog title="Avatar"  :modal-append-to-body="false" :visible.sync="dialogVisible1">
  
    <el-upload
      action="https://jsonplaceholder.typicode.com/posts/"
      list-type="picture-card"
      :limit="1"
      accept=".png,.jpg,.gif,.svg"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove">
      
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :modal-append-to-body="false" :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="uploadPicture" type="primary" :loading="isUploadPic">Upload</el-button>
      
      </div>

    </el-dialog>
</div>
</template>
<script>
    // import moment from "moment"
    export default {
        name:"orderDetails",
        data(){
            return {
                // imageUrl:require("@/assets/images/avatar.png"),
                imageUrl:'',
                dialogImageUrl: '',
                isUploadPic:false,
                dialogVisible: false,
                dialogVisible1:false,
                detailData:{},
                pdTableData:[],
                outTableData:[],
            }
        },
        // props:['orderNum'],
        created(){
            
        },
        mounted(){
           // console.log("123",this.orderNum);
           // this.getTableData();
        },
        methods: {
            //点击 按钮 弹窗上传
            handleForUpload() {
              this.dialogVisible1 = true;
            },
            //点击 upload btn 开始 上传
            uploadPicture() {
              // 将上传的文件附件转成二进制转给后台 this.form就是表单输入框的内容
              const formData = new FormData();
              formData.append("file", this.file);
              // this.isUploadPic = true;
            //   uploadOrganizationAvatar(formData).then(async (res) => {
            //     this.refresh_info();
            //     this.dialogVisible = false;
            //     // this.isUploadPic = false;
            //   });
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handlePictureCardPreview(file) {
             this.dialogImageUrl = file.url;
             this.dialogVisible = true;
            },

            getTableData(isFeater){
                this.isFetching=true;
                if(isFeater){
                    this.fetchOptions.page=1;
                }
                this.$store.dispatch("PMC_ORDER_DETAIL",{
                    order_sn:this.orderNum
                }).then((res)=>{
                    console.log("orderDetail",res)
                    this.detailData=res.list.det;
                    this.pdTableData=res.list.production;
                    this.outTableData=res.list.out;
                })
            },
            
        },
        watch: {
            orderNum(){
                // this.getTableData();
            }
        },
    };
</script>

<style lang="less" scoped>
.box-card{
	margin:15px;
	/deep/.el-card__header{
	padding:10px 20px;
  }
}

.clearfix:before,.clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
  .goback{
  	font-size:16px;
  	margin-top:10px;
  	display: inline-block;
  	cursor:pointer;
  }
   .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }

  // .disabled .el-upload--picture-card {
  //     display: none !important;
  //   }

</style>
